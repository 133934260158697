.icon-arrow-left {
  width: 1.38em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-next {
  width: 0.57em;
  height: 1em;
  fill: #9F9FA9;
}
.icon-arrow-prev {
  width: 0.57em;
  height: 1em;
  fill: #9F9FA9;
}
.icon-arrow-right {
  width: 1.38em;
  height: 1em;
  fill: #fff;
}
.icon-arrows {
  width: 0.67em;
  height: 1em;
  fill: #111029;
}
.icon-dribbble {
  width: 1em;
  height: 1em;
  fill: #111029;
}
.icon-email {
  width: 1.2em;
  height: 1em;
  fill: #4C40F7;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #9F9FA9;
}
.icon-message {
  width: 1em;
  height: 1em;
  fill: #111029;
}
.icon-phone {
  width: 0.94em;
  height: 1em;
  fill: #4C40F7;
}
.icon-place {
  width: 1em;
  height: 1em;
  fill: #4C40F7;
}
.icon-profile {
  width: 0.94em;
  height: 1em;
  fill: #111029;
}
.icon-send {
  width: 1em;
  height: 1em;
  fill: #4C40F7;
}
